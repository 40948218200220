
import tw, { css } from "twin.macro";
import { Stack, Text, Tooltip } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { TimeEntry, DayOff, UserTimeSummaryDocument, PersonAnnualTimeTarget } from "../../codegen/graphql-types";
import { hoursToMinutes, minutesToHours } from "../../helpers/time";
import { FullSpinner } from "../../FullSpinner";
import { sumEntryMinutes, daysInDateRange } from "../dates";
import { TimeLogsByWeek } from "./TimeLogsByWeek";
import { SelectYear } from "../../components/SelectYear";
import { nonBillableCompanyIds } from "../constants";
import { WeekSelector } from "./WeekSelector";
import TimeSummaryRow from "./TimeSummaryRow";
import FluxCapacityRow from "./FluxCapacityRow";
import { format } from "date-fns";
import { getTargetPerDay, getTargetTotal } from "../../helpers/time";
import { sumUtilizedTimeParts, getNonUtilizedTimeEntries } from "../../helpers/sumUtilizedTimeParts";

type UserTimeSummaryData = {
  me: {
    id: string;
    first_name: string;
    last_name: string;
    created_at: string;
    annual_time_targets: PersonAnnualTimeTarget[];
    timeEntries: TimeEntry[];
    daysOff: DayOff[];
  }
}

type TimeSummaryForUserProps = {
  userTimeSummary: UserTimeSummaryData;
  yearInFocus: number;
  setYearInFocus: (year: number) => void;
  weekInFocus: number;
  setWeekInFocus: (week: number) => void;
}

export const TotalTimeForYear = ({
  startDate,
  endOfRangeDate,
  timeEntries,
  numOfWorkingDaysYearToDate,
  summaryOfDaysString,
}: {
  startDate: Date;
  endOfRangeDate: Date;
  timeEntries: TimeEntry[];
  numOfWorkingDaysYearToDate: number;
  summaryOfDaysString: string;
}): JSX.Element => {

  const totalMinutes: number = timeEntries.reduce(sumEntryMinutes, 0);
  const totalHours: number = minutesToHours(totalMinutes);
  const totalHoursPerDay: string = (totalHours / numOfWorkingDaysYearToDate).toFixed(1);

  return (
    <td css={tw`px-4 py-1 text-center`}>
      <Tooltip 
        label={
          <Stack>
            <Text>{`From ${format(startDate, "MMM dd yyyy")} to ${format(endOfRangeDate, "MMM dd yyyy")}:`}</Text>
            <Text>{`${minutesToHours(totalMinutes)} hours logged`}</Text>
            <Text>&divide;</Text>
            <Text>{`${numOfWorkingDaysYearToDate} total working days ${summaryOfDaysString}`}</Text>
          </Stack>
        }  
        aria-label="A tooltip"
        >
        <div css={tw`cursor-pointer`}>
          {totalHoursPerDay}
        </div>
      </Tooltip>
    </td>
  )
};

// Get time entries for user and display the average billable hours
// logged for this week, last 4 weeks, last 3 months, and this year in a table
export const TimeSummaryForUser = ({
  userTimeSummary,
  yearInFocus,
  setYearInFocus,
  weekInFocus,
  setWeekInFocus
}: TimeSummaryForUserProps) => {
  const yesterday = useMemo(() => {
    // Get current date in UTC
    const today = DateTime.utc();
  
    // Subtract one day and set to end of day in UTC
    const yesterday = today.minus({ days: 1 }).endOf('day');
  
    // Return as JavaScript Date object
    return yesterday.toJSDate();
  }, []);
  const currentYear: number = new Date().getFullYear();
  
  // start and end dates for the query for time entries for the year in focus
  const startDate: Date = new Date(yearInFocus, 0, 1); // Jan 1st, yearInFocus
  const endDate: Date = new Date(yearInFocus + 1, 0, 1); // Jan 1st, yearInFocus + 1

  const endOfRangeDate: Date = useMemo(() => {
    if (yearInFocus === currentYear) {
      return yesterday;
    } else {
      return endDate;
    }
  }, [yearInFocus, currentYear, yesterday]);

  const { data, loading, error, refetch } = useQuery(UserTimeSummaryDocument, {
    variables: {
      start_date: startDate,
      end_date: endDate,
      year: yearInFocus
    }
  });

  if (loading) return (
    <div css={tw`w-full justify-center items-center h-screen`}>
      <FullSpinner />
    </div>
  );

  if (error) 
    return (
      <div css={tw`w-full justify-center items-center`}>
        {error && <p><b>Error in query for full year time logs</b>: {error.message}</p>}
      </div>
    );

  const {
    me: {
      timeEntries: timeEntriesForYearInFocus,
      annual_time_targets: timeTargetsForUser,
      daysOff: daysOffForYearInFocus,
      created_at: createdAt
    }
  } = data as UserTimeSummaryData;

  // Last 3 months time range:
  const recentTimeEntries = userTimeSummary.me.timeEntries;
  const recentDaysOff = userTimeSummary.me.daysOff;

  // Target minutes for the year
  const targetForYear = timeTargetsForUser.find(
    (target: PersonAnnualTimeTarget) => target?.year === yearInFocus
  );

  // Actual time entries for the year => map of utilized time entries by Time tag category name
  const utilizedTimeParts = sumUtilizedTimeParts(timeEntriesForYearInFocus);
  const recentUtilizedTimeParts = sumUtilizedTimeParts(recentTimeEntries);
  
  // Non-billable time entries are any entries where the company_id is in the nonBillableCompanyIds array
  const nonBillableTimeEntriesForYear = timeEntriesForYearInFocus.filter((entry: TimeEntry) => nonBillableCompanyIds.includes(entry.company_id));
  const nonBillableTimeEntriesLast3Months = recentTimeEntries.filter((entry: TimeEntry) => nonBillableCompanyIds.includes(entry.company_id));
  const recentNonUtilizedTimeEntries = getNonUtilizedTimeEntries(nonBillableTimeEntriesLast3Months);
  const yearNonUtilizedTimeEntries = getNonUtilizedTimeEntries(nonBillableTimeEntriesForYear);

  const nonBillableMinutes: number = timeEntriesForYearInFocus
    .filter((entry: TimeEntry) => nonBillableCompanyIds.includes(entry.company_id))
    .reduce(sumEntryMinutes, 0);
  const billableMinutes: number = timeEntriesForYearInFocus
    .filter((entry: TimeEntry) => !nonBillableCompanyIds.includes(entry.company_id))
    .reduce(sumEntryMinutes, 0);

  const teamworkUserCreatedDate = DateTime.fromISO(createdAt).toJSDate();
  const adjustedStartDate = teamworkUserCreatedDate > startDate && yearInFocus === currentYear ? teamworkUserCreatedDate : startDate;
  
  // Keep in mind that when we log a JS Date object to the console, it is displayed in the local timezone
  // But internally, the Date object is always stored in UTC (this is how dates should be compared and stored in the database)
  // This is why we add .toUTCString() to the console.log() statements
  // console.log("adjustedStartDate: ", adjustedStartDate.toUTCString());
  // console.log("endOfRangeDate: ", endOfRangeDate.toUTCString());
  
  const {
    numberOfWorkingDaysInRange: numOfWorkingDaysYearToDate,
    userDaysOffWithinInterval: daysOffYearToDate
  } = daysInDateRange(
    adjustedStartDate,
    endOfRangeDate,
    daysOffForYearInFocus
  );
  
  const summaryOfDaysString = `(Subtracted ${daysOffYearToDate.length} days off for user)`;

  // Totals of billable & non-billable mins / working day for the year
  //const totalNonBillablePerDay = minutesToHours(nonBillableMinutes / numOfWorkingDaysYearToDate);
  const totalBillablePerDay = minutesToHours(billableMinutes / numOfWorkingDaysYearToDate);

  // Utilized time Targets
  const billableTarget = getTargetPerDay(targetForYear, 'billableMinutes');
  const salesAndMarketingTarget = getTargetPerDay(targetForYear, 'salesAndMarketingMinutes');
  const innovationAndDevTarget = getTargetPerDay(targetForYear, 'innovationAndDevMinutes');
  const operationalTarget = getTargetPerDay(targetForYear, 'operationalMinutes');
  const professionalDevTarget = getTargetPerDay(targetForYear, 'professionalDevMinutes');
  // Total time target
  const totalTarget = getTargetTotal(targetForYear);

  return (
    <>
      <div css={tw`p-3 flex flex-col items-center justify-center w-full gap-y-2 rounded-md border-2`}>
        <div css={tw`relative flex justify-between items-center w-full`}>
          <h2 css={tw`px-4 text-center w-full`}>
            <strong css={tw`text-lg`}>{data.me.first_name} {data.me.last_name}<br/>hours/day</strong>
          </h2>
          <SelectYear title="Year" yearInFocus={yearInFocus} setYearInFocus={setYearInFocus} />
        </div>
        <div css={tw`text-xl w-full`}>
          <table css={[tw`w-full`, css`
            font-size: 1rem;
            border-collapse: collapse;
            border-spacing: 0;
            tr:not(:nth-last-of-type(-n+2)) {
              border-bottom: 2px dotted #E2E8F0;
            }
          `]}>
            <thead>
              <tr>
                <th css={tw`px-4 py-4 text-right w-1/4`}>&nbsp;</th>
                <th css={tw`px-3 py-4 border-t-2 border-black`}>Target</th>
                <th css={tw`px-3 py-4 border-t-2 border-black`}>Last week</th>
                <th css={tw`px-3 py-4 border-t-2 border-black`}>Last 4 weeks</th>
                <th css={tw`px-3 py-4 border-t-2 border-black`}>Last 3 months</th>
                <th css={tw`px-3 py-4 border-t-2 border-black`}>Year to date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th css={tw`px-3 py-1 text-right text-green-500 text-base`}>Billable</th>
                <td css={tw`px-3 py-1 text-center`}>{billableTarget ? billableTarget.toFixed(1) : null}</td>
                {/* Three column td's are in the TimeSummaryRow component: Last Week, Last 4 Weeks, Last 3 Months */}
                <TimeSummaryRow 
                  timeEntries={recentTimeEntries}
                  isBillable={true}
                  targetForYearInMinutes={targetForYear?.billableMinutes}
                  daysOff={recentDaysOff}
                />
                <td css={tw`px-3 py-1 text-center`}>
                  <Tooltip 
                    label={
                      <Stack>
                        <Text>{`From ${format(adjustedStartDate, "MMM dd yyyy")} to ${format(endOfRangeDate, "MMM dd yyyy")}:`}</Text>
                        <Text>{`${minutesToHours(billableMinutes)} billable hours logged`}</Text>
                        <Text>&divide;</Text>
                        <Text>{`${numOfWorkingDaysYearToDate} total working days ${summaryOfDaysString}`}</Text>
                        <Text>= {`${totalBillablePerDay} billable hours/day`}</Text>
                      </Stack>
                    }
                    aria-label="A tooltip"
                  >
                    {targetForYear ? (
                      <div 
                        style={{ color: totalBillablePerDay < minutesToHours(targetForYear.billableMinutes) ? "red" : "unset" }}
                        css={tw`cursor-pointer`}>
                        {totalBillablePerDay}
                      </div>
                    ) : <span>No target set</span>}
                  </Tooltip>
                </td>
              </tr>
              {salesAndMarketingTarget ? (
                <tr>
                  <th css={tw`px-3 py-1 text-right text-green-500 text-base`}>Sales & Marketing</th>
                  <td css={tw`px-3 py-1 text-center`}>{salesAndMarketingTarget.toFixed(1)}</td>
                  {/* Three column td's are in the TimeSummaryRow component: Last Week, Last 4 Weeks, Last 3 Months */}
                  <TimeSummaryRow 
                    timeEntries={recentUtilizedTimeParts["Time: Sales and marketing"]}
                    isBillable={false}
                    targetForYearInMinutes={targetForYear?.salesAndMarketingMinutes}
                    daysOff={recentDaysOff}
                  />
                  <TotalTimeForYear 
                    startDate={adjustedStartDate}
                    endOfRangeDate={endOfRangeDate}
                    timeEntries={utilizedTimeParts["Time: Sales and marketing"]}
                    numOfWorkingDaysYearToDate={numOfWorkingDaysYearToDate}
                    summaryOfDaysString={summaryOfDaysString}
                  />
                </tr>
              ): null}
              {innovationAndDevTarget ? (
                <tr>
                  <th css={tw`px-4 py-1 text-right text-green-500 text-base`}>Innovation & development</th>
                  <td css={tw`px-3 py-1 text-center`}>{innovationAndDevTarget.toFixed(1)}</td>
                  {/* Three column td's are in the TimeSummaryRow component: Last Week, Last 4 Weeks, Last 3 Months */}
                  <TimeSummaryRow 
                    timeEntries={recentUtilizedTimeParts["Time: Innovation and development"]}
                    isBillable={false}
                    targetForYearInMinutes={targetForYear?.innovationAndDevMinutes}
                    daysOff={recentDaysOff}
                  />
                  <TotalTimeForYear 
                    startDate={adjustedStartDate}
                    endOfRangeDate={endOfRangeDate}
                    timeEntries={utilizedTimeParts["Time: Innovation and development"]}
                    numOfWorkingDaysYearToDate={numOfWorkingDaysYearToDate}
                    summaryOfDaysString={summaryOfDaysString}
                  />
                </tr>
              ): null}
              {operationalTarget ? (
                <tr>
                  <th css={tw`px-4 py-1 text-right text-green-500 text-base`}>Operational</th>
                  <td css={tw`px-3 py-1 text-center`}>{operationalTarget.toFixed(1)}</td>
                  {/* Three column td's are in the TimeSummaryRow component: Last Week, Last 4 Weeks, Last 3 Months */}
                  <TimeSummaryRow 
                    timeEntries={recentUtilizedTimeParts["Time: Operational activities"]}
                    isBillable={false}
                    targetForYearInMinutes={targetForYear?.operationalMinutes}
                    daysOff={recentDaysOff}
                  />
                  <TotalTimeForYear 
                    startDate={adjustedStartDate}
                    endOfRangeDate={endOfRangeDate}
                    timeEntries={utilizedTimeParts["Time: Operational activities"]}
                    numOfWorkingDaysYearToDate={numOfWorkingDaysYearToDate}
                    summaryOfDaysString={summaryOfDaysString}
                  />
                </tr>
              ): null}
              {professionalDevTarget ? (
                <tr>
                  <th css={tw`px-4 py-1 text-right text-green-500 text-base`}>Professional development</th>
                  <td css={tw`px-3 py-1 text-center`}>{professionalDevTarget.toFixed(1)}</td>
                  {/* Three column td's are in the TimeSummaryRow component: Last Week, Last 4 Weeks, Last 3 Months */}
                  <TimeSummaryRow 
                    timeEntries={recentUtilizedTimeParts["Time: Professional development"]}
                    isBillable={false}
                    targetForYearInMinutes={targetForYear?.professionalDevMinutes}
                    daysOff={recentDaysOff}
                  />
                  <TotalTimeForYear 
                    startDate={adjustedStartDate}
                    endOfRangeDate={endOfRangeDate}
                    timeEntries={utilizedTimeParts["Time: Professional development"]}
                    numOfWorkingDaysYearToDate={numOfWorkingDaysYearToDate}
                    summaryOfDaysString={summaryOfDaysString}
                  />
                </tr>
              ): null}
              <tr>
                <th css={tw`px-4 py-1 text-right text-red-500 text-base whitespace-nowrap`}>Internal meetings & administratrive tasks</th>
                <td css={tw`px-3 py-1 text-center`}>
                  {/* We intentionally do not show this target because we want to minimize this time */}
                  {/* {getTargetPerDay(targetForYear, 'nonBillableMinutes')} */}
                  &nbsp;
                </td>
                {/* Three column td's are in the TimeSummaryRow component: Last Week, Last 4 Weeks, Last 3 Months */}
                <TimeSummaryRow 
                  timeEntries={recentNonUtilizedTimeEntries}
                  isBillable={false}
                  daysOff={recentDaysOff}
                />
                <TotalTimeForYear 
                  startDate={adjustedStartDate}
                  endOfRangeDate={endOfRangeDate}
                  timeEntries={yearNonUtilizedTimeEntries}
                  numOfWorkingDaysYearToDate={numOfWorkingDaysYearToDate}
                  summaryOfDaysString={summaryOfDaysString}
                />
              </tr>
              <tr>
                <th css={tw`px-4 py-1 text-right text-base`}>Total</th>
                <td css={tw`px-3 py-1 text-center text-base`}>{totalTarget ? totalTarget.toFixed(1) : null}</td>
                {/* Three column td's are in the TimeSummaryRow component: Last Week, Last 4 Weeks, Last 3 Months */}
                <TimeSummaryRow 
                  timeEntries={recentTimeEntries}
                  isTotal={true}
                  daysOff={recentDaysOff}
                />
                <td css={tw`px-4 py-1 text-center cursor-pointer`}>
                  <Tooltip 
                    label={
                      <Stack>
                        <Text>{`From ${format(adjustedStartDate, "MMM dd yyyy")} to ${format(endOfRangeDate, "MMM dd yyyy")}:`}</Text>
                        <Text>{`${minutesToHours(billableMinutes + nonBillableMinutes)} total hours logged`}</Text>
                        <Text>&divide;</Text>
                        <Text>{`${numOfWorkingDaysYearToDate} total working days ${summaryOfDaysString}`}</Text>
                      </Stack>
                    }
                    aria-label="A tooltip"
                  >
                    <div>
                      {minutesToHours((billableMinutes + nonBillableMinutes) / numOfWorkingDaysYearToDate).toFixed(1)}
                    </div>
                  </Tooltip>
                </td>
              </tr>
              {targetForYear && (
                <FluxCapacityRow
                  totalTargetMinutesPerDay={hoursToMinutes(totalTarget)}
                  recentTimeEntries={recentTimeEntries}
                  timeEntriesForYearInFocus={timeEntriesForYearInFocus}
                  startDate={adjustedStartDate}
                  yearInFocus={yearInFocus}
                  daysOff={recentDaysOff}
                  daysOffForYearInFocus={daysOffForYearInFocus}
                />
              )}
              {!targetForYear && (
                <tr>
                  <th colSpan={4} css={tw`px-4 py-1 text-left`}>Target for {yearInFocus} not set</th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div css={tw`flex w-full px-1`}>
        <WeekSelector
          yearInFocus={yearInFocus}
          weekInFocus={weekInFocus}
          setWeekInFocus={setWeekInFocus}
          setYearInFocus={setYearInFocus}
        />
        <p css={tw`w-full flex items-center`}>
        <a
          css={tw`cursor-pointer underline`}
          href="https://mavenmm.teamwork.com/spaces/working-at-maven/page/32385-m623-tracking-and-logging-time#expectations-by-role-type" 
          target="_blank"
        >
          Spaces
        </a>&nbsp;|&nbsp;
        <a
          css={tw`cursor-pointer underline`}
          href="https://mavenmm.teamwork.com/app/reports/time" 
          target="_blank"
        >
          Teamwork Report
        </a>
      </p>
      </div>
      <div css={tw`p-1 flex flex-col items-start justify-center gap-y-6 w-full rounded-md border-2`}>
        <TimeLogsByWeek
          user={data.me}
          daysOff={daysOffForYearInFocus}
          userTimeEntriesForYear={timeEntriesForYearInFocus}
          yearInFocus={yearInFocus}
          weekInFocus={weekInFocus}
          refetchYearTimeEntries={refetch}
        />
      </div>
    </>
  )
}