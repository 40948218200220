import { gql, useQuery } from "@apollo/client";
import constate from "constate";
import { useMemo } from "react";
import type * as GraphQLTypes from "../codegen/graphql-types";

const usePeople = () => {
  const queryResult = useQuery<GraphQLTypes.PeopleDataQuery>(gql`
    query PeopleData {
      me {
        ...Person
        pods {
          id
          name
          senior_brand_maven_lead
          members {
            id
            first_name
          }
        }
      }
      people {
        ...Person
      }
      pods {
        id
        name
        senior_brand_maven_lead
        members {
          id
        }
      }
    }
    fragment Person on Person {
      id
      first_name
      last_name
      title
      rate
      email_address
      company
      pods {
        id
        senior_brand_maven_lead
      }
      role {
        id
        roleTitle
      }
      team {
        id
        teamName
      }
    }
  `);
  return useMemo(() => ({
    ...queryResult,
    peopleByPersonId: (queryResult.data?.people ?? []).reduce((acc, person) => {
      if (!person) return acc;
      return {
        ...acc,
        [person.id]: person,
      };
    }, {} as Record<string, GraphQLTypes.PersonFragment>),
  }), [
    queryResult,
  ]);
};

export const [PeopleProvider, usePeopleContext] = constate(usePeople);
