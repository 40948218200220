/**
 * There are layers of data used throughout the page
 * 1) Project Selector
 * Search/Fetch project name from synced data in DB from TW
 * a) New pricing, fetch project data + tasklist from TW API
 * b) Existing pricing, fetch
 *
 * 2) Project Pricing Table
 * Fetch data from DB, will have to transpose for table use
 * Here are the types of tables
 * - Tasklist
 * - COGS
 * - Total/Subtotal
 *
 * 3) Tasklist Details Table
 * Fetch data from DB, will have to transpose for table use
 * Here are the types of tables
 * - Team
 * - Total
 *
 * The data is used in different components and it is passed around using context.
 */
import { createContext, useState, ReactNode, useMemo, useEffect } from "react";
import { useAuthContext } from "../useAuth";

// For development only
import pricingMockData from "../mock-data/pricing.json";

interface PricingContextType {
  // 0.Utils
  formatNumber: (num: number) => string;

  // 1.Project Selector Section
  selectedProjectId: number | undefined;
  setSelectedProjectId: (id: number | undefined) => void;
  handleSelectProject: (option: { value?: string }) => void;
  pricingAppData: PricingAppData | null;
  setPricingAppData: (data: PricingAppData | null) => void;

  // 2.Tasklist Table Section
  transposedProjectTasklistsData: TransposedDataForTasklistTable[];
  setTransposedProjectTasklistsData: (
    data: TransposedDataForTasklistTable[]
  ) => void;
  selectedProjectTasklists: Tasklist[];
  setSelectedProjectTasklists: (tasklists: Tasklist[]) => void;
  selectedTasklist: Tasklist | null;
  setSelectedTasklist: (tasklist: Tasklist | null) => void;

  // 3.Tasklist Detail Section
  currentDetailView: "tasklist" | "cogs";
  setCurrentDetailView: (view: "tasklist" | "cogs") => void;
  projectedTimes: ProjectedTime[];
  projectedTimesCalculatedData: ProjectPricingCalculatedData;
  updateTeamRoleHour: (
    tasklistId: number,
    columnId: string,
    value: number
  ) => void;
  convertTasklistDetailsRowDataByTeam: (
    teamName: string,
    data: ProjectedTime[]
  ) => void;
  tasklistDetailsGroupedByTeamData: TasklistDetailsGroupedByTeamData;
  transposedTasklistDetailsData:
    | [
        TransposedDataForTasklistTableEntry,
        TransposedDataForTasklistTableEntry,
        TransposedDataForTasklistTableEntry
      ]
    | [{}, {}, {}];
}

export const PricingContext = createContext<PricingContextType>({
  // Initialize context
  // 0.Utils
  formatNumber: () => "",

  // 1.Project Selector Section
  selectedProjectId: undefined,
  setSelectedProjectId: () => {},
  handleSelectProject: () => {},
  pricingAppData: null,
  setPricingAppData: () => {},

  // 2.Tasklists Table Section
  transposedProjectTasklistsData: [],
  setTransposedProjectTasklistsData: () => {},
  selectedProjectTasklists: [],
  setSelectedProjectTasklists: () => {},
  selectedTasklist: null,
  setSelectedTasklist: () => {},

  // 3.Tasklist Team Table Section
  currentDetailView: "tasklist",
  setCurrentDetailView: () => {},
  projectedTimes: [],
  projectedTimesCalculatedData: {
    budget: {},
    actual: {},
    total: {},
  },
  convertTasklistDetailsRowDataByTeam: () => {},
  tasklistDetailsGroupedByTeamData: {},
  transposedTasklistDetailsData: [],

  updateTeamRoleHour: () => {},
});

/*
Interface for Pricing Context
*/

interface Cogs {
  // Define properties if there are any COGS data
}

interface ProjectPricingCalculatedData {
  budget: { [key: string]: number };
  actual: { [key: string]: number };
  total: { [key: string]: number };
}

interface ProjectPricing {
  CALCULATED?: ProjectPricingCalculatedData;
  id: number;
  tasklist_id: number;
  tasklist_name: string;
  comments: string;
  actual_cost: number;
  projected_times?: ProjectedTime[];
}

interface ProjectedTime {
  id: number;
  project_estimate_tasklist_id: number;
  role: string;
  team: string;
  budget_cost: number;
  actual_cost: number;
  budget_hours: number;
  actual_hours: number;
}

// This is based on what we get from TW API
interface Tasklist {
  id: number;
  name: string;
  description: string;
  displayOrder: number;
  projectId: number;
  project: {
    id: number;
    type: string;
  };
  milestoneId: number | null;
  milestone: {
    id: number;
    type: string;
  } | null;
  isPinned: boolean;
  isPrivate: boolean;
  lockdownId: number | null;
  status: string;
  defaultTaskId: number;
  defaultTask: {
    id: number;
    type: string;
  };
  isBillable: boolean | null;
  tasklistBudget: number | null;
  createdAt: string;
  updatedAt: string;
  icon: string | null;
}

interface PricingAppDataSource {
  tasklists: Tasklist[];
  meta: {
    page: {
      pageOffset: number;
      pageSize: number;
      count: number;
      hasMore: boolean;
    };
  };
  included: {};
}

interface PricingAppData {
  db: {
    project_pricings: ProjectPricing[];
    cogs: Cogs;
  };
  projectId?: number | string;
  tasklists: Tasklist[];
}

interface PricingProviderProps {
  children: ReactNode;
}

// Interface for Tasklists Table
interface TransposedDataForTasklistTable {
  type: string;
  [key: string]: { value: number } | string;
}

// Interface for Tasklist Details Table
interface TasklistDetailsGroupedByTeamData {
  [key: string]: ProjectedTime[];
}

interface TransposedDataForTasklistTableEntry {
  id: number;
  "total-cost": number;
  "total-time": number;
  [key: string]: number;
}

export const PricingProvider = ({ children }: PricingProviderProps) => {
  const { accessToken } = useAuthContext();
  const [selectedProjectId, setSelectedProjectId] = useState<
    number | undefined
  >();
  const [selectedProjectTasklists, setSelectedProjectTasklists] = useState<
    Tasklist[]
  >([]);
  const [selectedTasklist, setSelectedTasklist] = useState<Tasklist | null>(
    null
  );

  // Determine the detail view from tasklist selection
  const [currentDetailView, setCurrentDetailView] = useState<
    "tasklist" | "cogs"
  >("tasklist");

  const [projectedTimes, setProjectedTimes] = useState<ProjectedTime[]>([]);

  const [
    projectedTimesCalculatedData,
    setProjectedTimesCalculatedData,
  ] = useState<ProjectPricingCalculatedData>({
    budget: {},
    actual: {},
    total: {},
  });

  // Transposed data for tasklist table
  const [
    transposedProjectTasklistsData,
    setTransposedProjectTasklistsData,
  ] = useState<TransposedDataForTasklistTable[]>([]);

  // Will use this as SSOT data
  // All outside data will be manage here before passing down to children
  const [pricingAppData, setPricingAppData] = useState<PricingAppData | null>(
    null
  );

  const formatNumber = (num: number) => {
    return new Intl.NumberFormat().format(num);
  };

  const dedupeWithSet = (array: string[] | undefined) => {
    return Array.from(new Set(array));
  };

  const extractTeams = (projectPricing: ProjectPricing) =>
    projectPricing?.projected_times?.map((pt: ProjectedTime) => pt.team);

  const sumByTeam = (
    source: ProjectedTime[],
    team: string,
    rowName: "budget" | "actual",
    sumType: "cost" | "time"
  ) => {
    const costKey = rowName === "budget" ? "budget_cost" : "actual_cost";
    const timeKey = rowName === "budget" ? "budget_hours" : "actual_hours";

    // If sumType is for time
    if (sumType === "time") {
      return source.reduce(
        (acc: number, curr: ProjectedTime) =>
          curr.team === team ? acc + curr[timeKey] : acc,
        0
      );
    }

    // If sumType is for cost
    if (sumType === "cost") {
      return source.reduce(
        (acc: number, curr: ProjectedTime) =>
          curr.team === team ? acc + curr[timeKey] * curr[costKey] : acc,
        0
      );
    }

    return 0;
  };

  const sumTotal = (source: { [key: string]: number }, keyName: string) => {
    return Object.keys(source).reduce(
      (acc: number, curr: string) =>
        curr.includes(keyName) ? acc + source[curr] : acc,
      0
    );
  };

  const calculateTotalTimeCost = (projectPricing: ProjectPricing) => {
    let calculatedData: ProjectPricingCalculatedData = {
      budget: {},
      actual: {},
      total: {},
    };

    dedupeWithSet(extractTeams(projectPricing)).forEach((teamName: string) => {
      // Budget
      // Total Cost per team
      calculatedData.budget[`${teamName}-total-cost`] = sumByTeam(
        projectPricing.projected_times ?? [],
        teamName,
        "budget",
        "cost"
      );
      // Total Time per team
      calculatedData.budget[`${teamName}-total-time`] = sumByTeam(
        projectPricing.projected_times ?? [],
        teamName,
        "budget",
        "time"
      );
      // Total Cost
      calculatedData.budget["total-cost"] = sumTotal(
        calculatedData.budget,
        "-total-cost"
      );
      // Total Time
      calculatedData.budget["total-time"] = sumTotal(
        calculatedData.budget,
        "-total-time"
      );

      // Actual
      // Total Cost per team
      calculatedData.actual[`${teamName}-total-cost`] = sumByTeam(
        projectPricing.projected_times ?? [],
        teamName,
        "actual",
        "cost"
      );
      // Total Time per team
      calculatedData.actual[`${teamName}-total-time`] = sumByTeam(
        projectPricing.projected_times ?? [],
        teamName,
        "actual",
        "time"
      );
      // Total Cost
      calculatedData.actual["total-cost"] = sumTotal(
        calculatedData.actual,
        "-total-cost"
      );
      // Total Time
      calculatedData.actual["total-time"] = sumTotal(
        calculatedData.actual,
        "-total-time"
      );

      calculatedData.total[`${teamName}-total-cost`] =
        Number(calculatedData.budget[`${teamName}-total-cost`]) +
        Number(calculatedData.actual[`${teamName}-total-cost`]);
      calculatedData.total[`${teamName}-total-time`] =
        Number(calculatedData.budget[`${teamName}-total-time`]) +
        Number(calculatedData.actual[`${teamName}-total-time`]);
    });

    calculatedData.total["total-cost"] = (
      projectPricing.projected_times ?? []
    ).reduce((acc: number, curr: ProjectedTime) => {
      return acc + curr.budget_cost * curr.budget_hours;
    }, 0);

    calculatedData.total["total-time"] = (
      projectPricing.projected_times ?? []
    ).reduce((acc: number, curr: ProjectedTime) => {
      return acc + curr.budget_hours;
    }, 0);

    return {
      ...projectPricing,
      CALCULATED: calculatedData,
    };
  };

  // Set pricing app data as state
  // Lets keep all data processed here, and provide CRUD logics afterward
  // Reason is the Tasklist tables and Details are sharing data
  useEffect(() => {
    const projectPricingsWithCalculatedData = pricingMockData?.db?.project_pricings?.map(
      (projectPricing: ProjectPricing) => calculateTotalTimeCost(projectPricing)
    );

    setPricingAppData(() => {
      const newData: PricingAppData = {
        projectId: 663771, // Force selected project for development use
        tasklists:
          (pricingMockData?.teamworkGetTasklists?.tasklists as Tasklist[]) ??
          [],
        db: pricingMockData
          ? {
              ...pricingMockData?.db,
              project_pricings: projectPricingsWithCalculatedData,
            }
          : {
              project_pricings: [],
              cogs: {},
            },
      };

      return newData;
    });
  }, [pricingMockData]);

  // useEffect(() => {
  //   console.log("pricing app data:", pricingAppData);
  // }, [pricingAppData]);

  // Dev use: Set project selection from pricing app data
  useEffect(() => {
    setSelectedProjectId(Number(pricingAppData?.projectId) ?? undefined);
    setSelectedProjectTasklists(pricingAppData?.tasklists ?? []);
  }, [pricingAppData]);

  // Reset project selection
  const resetProjectSelection = () => {
    setSelectedProjectId(undefined);
    setSelectedProjectTasklists([]);
  };

  // Fetch tasklists from TW
  const fetchTasklists = async (projectId: string) => {
    try {
      const response = await fetch(
        `http://localhost:4000/teamwork/project-tasklists?projectId=${projectId}`,
        {
          headers: {
            "x-api-key": accessToken!,
          },
        }
      );
      return await response.json();
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch tasklist from TW, use it when project is selected
  const handleSelectProject = async (option: { value?: string }) => {
    try {
      resetProjectSelection();
      setSelectedProjectId(Number(option?.value));

      const tasklistJson = await fetchTasklists(
        option?.value?.toString() ?? ""
      );
      setSelectedProjectTasklists(tasklistJson?.tasklists ?? []);
    } catch (err) {
      console.error(err);
    }
  };

  // Transpose data from TW to table format
  // The purpose is to set the row/column for the tasklist table, and match with DB data after
  useEffect(() => {
    // DB Project Pricings is data from DB
    const dbProjectPricings = pricingAppData?.db?.project_pricings;

    const transposeDataForTasklistTable = (source: Tasklist[]) => {
      // Selected Project Tasklists is data from TW
      // We need to pull data from DB and use that to calculate budget
      if (!source || source.length === 0) {
        return [];
      }

      // Budget row
      const budget: { [key: string]: { value: number } } = source.reduce(
        (acc, task) => {
          const dbData = dbProjectPricings?.find(
            (pricing: ProjectPricing) => pricing.tasklist_id === task.id
          );
          const budget = dbData?.CALCULATED?.budget?.["total-cost"] ?? 0;
          return {
            ...acc,
            [task.name]: {
              value: budget,
            },
          };
        },
        {}
      );

      // Actual row
      const actual: { [key: string]: { value: number } } = source.reduce(
        (acc, task) => {
          const dbData = dbProjectPricings?.find(
            (pricing: ProjectPricing) => pricing.tasklist_id === task.id
          );
          return {
            ...acc,
            [task.name]: {
              value: dbData?.actual_cost ?? 0,
            },
          };
        },
        {}
      );

      // Remaining row
      const remaining: { [key: string]: { value: number } } = source.reduce(
        (acc, task) => {
          const dbData = dbProjectPricings?.find(
            (pricing: ProjectPricing) => pricing.tasklist_id === task.id
          );
          const budget = dbData?.CALCULATED?.budget?.["total-cost"] ?? 0;
          return {
            ...acc,
            [task.name]: {
              value: budget - (dbData?.actual_cost ?? 0),
            },
          };
        },
        {}
      );

      // Complete transposed data object
      const transposedData: TransposedDataForTasklistTable[] = [
        {
          type: "Budget",
          ...budget,
        },
        {
          type: "Actual",
          ...actual,
        },
        {
          type: "Remaining",
          ...remaining,
        },
      ];

      return transposedData;
    };

    const data = transposeDataForTasklistTable(selectedProjectTasklists);
    setTransposedProjectTasklistsData(data);
  }, [selectedProjectTasklists, pricingAppData]);

  // Set projected times
  useEffect(() => {
    const dbProjectPricings = pricingAppData?.db?.project_pricings;

    if (dbProjectPricings && dbProjectPricings.length > 0) {
      const selectedTasklistProjectPricing = dbProjectPricings?.find(
        (pricing: ProjectPricing) =>
          pricing?.tasklist_id === selectedTasklist?.id
      );
      const projectedTimes =
        selectedTasklistProjectPricing?.projected_times ?? [];

      setProjectedTimes(projectedTimes);

      // Use Calculated Fields
      setProjectedTimesCalculatedData(
        () => selectedTasklistProjectPricing?.CALCULATED
      );
    } else {
      setProjectedTimes([]);
      setProjectedTimesCalculatedData({});
    }
  }, [selectedTasklist, pricingAppData]);

  /**
   * Tasklist Details Section
   */

  // This function is to structure the data used in each Team Column
  const convertTasklistDetailsRowDataByTeam = (teamName: string, data: any) => {
    // Initialize data with 0 total for both budget and actual
    const budget: ProjectPricingCalculatedData["budget"] = {};
    const actual: ProjectPricingCalculatedData["actual"] = {};

    // Loop through each item in the data
    data.forEach((item: any) => {
      const keyName = `${teamName}-${item.role}`;

      // Assign "Projected Budget" and "Actuals" to the initial data
      // Budget is based on user input on time (Hrs)
      // Cost should be provided in data source
      // ID
      budget[`id`] = item.id;

      // Time & Cost
      budget[`${keyName}-time`] = item.budget_hours;
      budget[`${keyName}-cost`] = item.budget_cost * item.budget_hours;

      // Totals
      budget[`${teamName}-total-time`] += item.budget_hours;
      budget[`${teamName}-total-cost`] += item.budget_cost * item.budget_hours;

      // Actual should use data from outside, since it's not editable it should be fixated
      // ID
      actual[`id`] = item.id;

      // Time & Cost
      actual[`${keyName}-time`] = item.actual_hours;
      actual[`${keyName}-cost`] = item.actual_cost;
    });

    return [
      { ...budget, [`total-time`]: 0, [`total-cost`]: 0 },
      { ...actual, [`total-time`]: 0, [`total-cost`]: 0 },
    ];
  };

  const tasklistDetailsGroupedByTeamData = useMemo(() => {
    const reducedData: TasklistDetailsGroupedByTeamData = projectedTimes.reduce(
      (acc: TasklistDetailsGroupedByTeamData, curr: ProjectedTime) => {
        const team = curr.team;
        if (!acc[team]) {
          acc[team] = [];
        }
        acc[team].push(curr);
        return acc;
      },
      {}
    );
    return reducedData;
  }, [projectedTimes]);

  // Merge all the sorted data into one array with the order of [budget, actual, total]
  const convertRowData = (data: TasklistDetailsGroupedByTeamData) => {
    const newData:
      | [
          TransposedDataForTasklistTableEntry,
          TransposedDataForTasklistTableEntry,
          TransposedDataForTasklistTableEntry
        ]
      | [{}, {}, {}] = [{}, {}, {}];

    Object.entries(data).forEach(([teamName, teamData]) => {
      const [budget, actual, total] = convertTasklistDetailsRowDataByTeam(
        teamName,
        teamData
      );
      newData[0] = {
        ...newData[0],
        ...budget,
        ...projectedTimesCalculatedData?.budget,
      };
      newData[1] = {
        ...newData[1],
        ...actual,
        ...projectedTimesCalculatedData?.actual,
      };
      newData[2] = {
        ...newData[2],
        ...total,
        ...projectedTimesCalculatedData?.total,
      };
    });

    return newData;
  };

  const transposedTasklistDetailsData = useMemo(() => {
    return convertRowData(tasklistDetailsGroupedByTeamData);
  }, [tasklistDetailsGroupedByTeamData]);

  // Update Team Role Hour
  const updateTeamRoleHour = (
    _: number, // Not in use for now
    columnId: string, // Consist of team-role-time
    value: number // New value from <input>
  ) => {
    // First find the Projected Time ID
    const target = projectedTimes.find(
      (row) => `${row.team}-${row.role}-time` === columnId
    );
    setPricingAppData((prevData) => {
      if (!prevData) {
        return null;
      }
      return {
        ...prevData,
        db: {
          ...prevData.db,
          project_pricings: prevData.db.project_pricings.map(
            (projectPricing: ProjectPricing) => {
              // Find the project pricing based on selected tasklist
              if (projectPricing.tasklist_id === selectedTasklist?.id) {
                // Find the target projected time, update value here
                projectPricing.projected_times?.forEach(
                  (projectedTime: ProjectedTime) => {
                    if (projectedTime.id === target?.id) {
                      projectedTime.budget_hours = Number(value);
                    }
                  }
                );
              }
              // Return with a new calculated data
              return calculateTotalTimeCost(projectPricing);
            }
          ),
        },
      };
    });
  };

  const value = {
    // Utils
    formatNumber,

    // Project Selector Section
    selectedProjectId,
    setSelectedProjectId,
    handleSelectProject,
    pricingAppData,
    setPricingAppData,

    // Tasklist Section
    transposedProjectTasklistsData,
    setTransposedProjectTasklistsData,
    selectedProjectTasklists,
    setSelectedProjectTasklists,
    selectedTasklist,
    setSelectedTasklist,

    // Detail Section
    currentDetailView,
    setCurrentDetailView,
    projectedTimes,
    projectedTimesCalculatedData,

    convertTasklistDetailsRowDataByTeam,
    tasklistDetailsGroupedByTeamData,
    transposedTasklistDetailsData,

    updateTeamRoleHour,
  };

  return (
    <PricingContext.Provider value={value}>{children}</PricingContext.Provider>
  );
};
