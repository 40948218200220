/** @jsxImportSource @emotion/react */

// Table heavily referenced from https://tanstack.com/table/v8/docs/framework/react/examples/editable-data

import React, { useEffect, useMemo, useContext, Fragment } from "react";
import tw, { css } from "twin.macro";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  getFilteredRowModel,
  getPaginationRowModel,
  RowData,
} from "@tanstack/react-table";
import { PricingContext } from "../helpers/usePricing";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateData: (taskname: string, keyname: string, value: unknown) => void;
    updateCogs: (index: number, value: number) => void;
  }
}

// Skipper for pagination reset
/*
function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}
  */

interface ProjectPricingTableProps {}

// Too many repeated styles, use this
const styles = {
  baseTh: tw`justify-center items-center border border-gray-200 px-2 py-1`,
  baseTd: tw`justify-center items-center border border-gray-200 px-2 py-1`,
};

const ProjectTasklistPricingTable: React.FC<ProjectPricingTableProps> = () => {
  const {
    formatNumber,
    currentDetailView,
    setCurrentDetailView,
    selectedTasklist,
    setSelectedTasklist,
    selectedProjectTasklists,
    transposedProjectTasklistsData, // Tasklist Data
    pricingAppData,
  } = useContext(PricingContext);

  const [cogs, setCogs] = React.useState<number[]>([10, 0]);

  // Create dynamic columns based on the data
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<any>();
    if (transposedProjectTasklistsData.length === 0) return [];

    return [
      // First column: Fixed titles (Budget, Actual, Remaining)
      columnHelper.accessor((row) => row.name, {
        id: "column-head",
        header: () => "", // Dynamic header based on the first item's name
        cell: ({ row }) => {
          const titles = ["Budget", "Actual", "Remaining"];
          return <td css={styles.baseTd}>{titles[row.index]}</td>;
        },
      }),
      ...selectedProjectTasklists.map((tasklist) => {
        return columnHelper.accessor((row) => row.name, {
          id: tasklist.name,
          header: () => (
            <button
              onClick={() => {
                setCurrentDetailView("tasklist");
                setSelectedTasklist(tasklist);
              }}
              css={{
                ...tw`text-gray-500 focus:outline-none`,
                ...(selectedTasklist?.id === tasklist.id
                  ? tw`text-blue-500`
                  : tw`text-gray-500`),
              }}
            >
              {tasklist.name}
            </button>
          ), // Dynamic header based on the first item's name
          cell: (info) => {
            const isRemaining = info?.row?.original?.type === "Remaining";
            const isNegative = info.row.original?.[tasklist.name]?.value < 0;
            return (
              <td css={styles.baseTd}>
                {isRemaining && isNegative ? (
                  <span css={tw`text-red-500`}>
                    $(
                    {formatNumber(
                      info.row.original?.[tasklist.name]?.value ?? 0
                    )}
                    )
                  </span>
                ) : (
                  <span>
                    $
                    {formatNumber(
                      info.row.original?.[tasklist.name]?.value ?? 0
                    )}
                  </span>
                )}
              </td>
            );
          },
        });
      }),

      // Subtotal based on hrs
      columnHelper.accessor((row) => row.name, {
        id: "hour-based-subtotal",
        header: () => "Subtotal based on hrs", // Dynamic header based on the first item's name
        cell: ({ row }) => {
          // get sum of all budget in data
          const dbProjectPricings = pricingAppData?.db?.project_pricings;
          const budgetSubtotal =
            dbProjectPricings?.reduce(
              (acc, pricing) =>
                acc + (pricing?.CALCULATED?.budget?.["total-cost"] ?? 0),
              0
            ) ?? 0;
          const actualSubtotal =
            dbProjectPricings?.reduce(
              (acc, pricing) =>
                acc + (pricing?.CALCULATED?.actual?.["total-cost"] ?? 0),
              0
            ) ?? 0;
          switch (row.original.type) {
            case "Budget":
              return (
                <td key={row.id} css={styles.baseTd}>
                  ${formatNumber(budgetSubtotal)}
                </td>
              );
            case "Actual":
              return (
                <td key={row.id} css={styles.baseTd}>
                  ${formatNumber(actualSubtotal)}
                </td>
              );
            case "Remaining":
              const remainingSubtotal = budgetSubtotal - actualSubtotal;
              return (
                <td key={row.id} css={styles.baseTd}>
                  {remainingSubtotal > 0 ? (
                    `$${formatNumber(remainingSubtotal)}`
                  ) : (
                    <span css={tw`text-red-500`}>
                      $({formatNumber(remainingSubtotal)})
                    </span>
                  )}
                </td>
              );
            default:
              return (
                <td key={row.id} css={styles.baseTd}>
                  ERR
                </td>
              );
          }
        },
      }),

      // COGS
      columnHelper.accessor((row) => row.name, {
        id: "cogs",
        header: () => (
          <button
            onClick={() => {
              setCurrentDetailView("cogs");
              setSelectedTasklist(null);
            }}
            css={{
              ...tw`text-gray-500 focus:outline-none`,
              ...(currentDetailView === "cogs"
                ? tw`text-blue-500`
                : tw`text-gray-500`),
            }}
          >
            COGS
          </button>
        ),
        cell: ({ row }) => {
          let initialValue = cogs[row.index];

          const onBlur = () => {
            table.options.meta?.updateCogs(row.index, value);
          };

          const [value, setValue] = React.useState(initialValue);

          // Apparently state change will not update this, may have to merge data into one object
          if (row.index === 2) {
            return (
              <td key={row.id} css={tw`justify-center items-center`}>
                ${cogs[1] - cogs[0]}
              </td>
            );
          }

          return (
            <td key={row.id} css={styles.baseTd}>
              <input
                type="number"
                value={value.toString()}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (isNaN(value)) {
                    setValue(0);
                  } else {
                    setValue(value);
                  }
                }}
                onBlur={onBlur}
                css={tw`text-center`}
              />
            </td>
          );
        },
      }),

      // TOTAL
      columnHelper.accessor((row) => row.name, {
        id: "total",
        header: () => "TOTAL",
        cell: ({ row }) => {
          switch (row.original.type) {
            case "Budget":
              // Sum of all budget in data
              const budgets = transposedProjectTasklistsData[0];
              const totalBudget = Object.values(budgets).reduce<number>(
                (acc, task): number => {
                  if (
                    typeof task === "object" &&
                    task !== null &&
                    "value" in task
                  ) {
                    return acc + (Number(task.value) || 0);
                  }
                  return acc;
                },
                0
              );

              // COG - Budget

              return <td css={styles.baseTd}>${formatNumber(totalBudget)}</td>;
            // get sum of all actual in data
            case "Actual":
              // Sum of all actual in data
              const actual = transposedProjectTasklistsData[1];
              const totalActual = Object.values(actual).reduce<number>(
                (acc, task): number => {
                  if (
                    typeof task === "object" &&
                    task !== null &&
                    "value" in task
                  ) {
                    return acc + (Number(task.value) || 0);
                  }
                  return acc;
                },
                0
              );
              return (
                <td key={row.id} css={styles.baseTd}>
                  ${formatNumber(totalActual)}
                </td>
              );
            case "Remaining":
              const remaining = 0;
              return (
                <td key={row.id} css={styles.baseTd}>
                  ${formatNumber(remaining)}
                </td>
              );
            default:
              return (
                <td key={row.id} css={styles.baseTd}>
                  0
                </td>
              );
          }
        },
      }),
    ];
  }, [selectedTasklist, transposedProjectTasklistsData, pricingAppData]);

  const table = useReactTable({
    data: transposedProjectTasklistsData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div>
      <table css={tw`border-collapse border border-gray-200`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} css={styles.baseTh}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <React.Fragment key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectTasklistPricingTable;
