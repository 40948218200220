/** @jsxImportSource @emotion/react */

import { useEffect, useState, useMemo } from "react";
import tw, { css } from "twin.macro";
/**
 * Team component for the tasklist pricing details
 *
 * Possible layout
 * 1. Team name
 * 2. Columns of seniority with total and cost
 * 3. Total
 */

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  Column,
  Table,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
  RowData,
} from "@tanstack/react-table";

const COGSTable = () => {
  const columns = useMemo(
    () => [
      {
        header: "Expense Type",
        id: "expense-type",
        accessorFn: (row: any) => row.expense_type,
      },
      {
        header: "Payee Name",
        id: "payee-name",
        accessorFn: (row: any) => row.payee_name,
      },
      {
        header: "Budgeted Amount",
        id: "budgeted-amount",
        accessorFn: (row: any) => row.budgeted_amount,
      },
      {
        header: "Actual Cost",
        id: "actual-cost",
        accessorFn: (row: any) => row.actual_cost,
      },
      {
        header: "Remaining",
        id: "remaining",
        accessorFn: (row: any) => row.remaining,
      },
    ],
    []
  );

  const table = useReactTable({
    data: [
      {
        expense_type: "Subcontractor",
        payee_name: "Google",
        budgeted_amount: 750,
        actual_cost: 750,
        remaining: 0,
      },
      {
        expense_type: "Regulatory Review",
        payee_name: "PAAB",
        budgeted_amount: 410,
        actual_cost: 1000,
        remaining: -590,
      },
      {
        budgeted_amount: 1160,
        actual_cost: 1750,
        remaining: -590,
      },
    ],
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });
  return (
    <div>
      <table css={tw`border-collapse border border-gray-200`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    css={tw`border border-gray-200 px-2 py-1`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} css={tw`border border-gray-200 text-center`}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default COGSTable;
