/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo, useContext } from "react";
import { PricingContext } from "../../helpers/usePricing";
import TasklistDetailsTeamTable from "./TasklistDetailsTeamTable";
import COGSTable from "./COGSTable";

/**
 * This is the component that shows the detail of each tasklist selected from the tasklist table
 *
 * This component will consist of two parts:
 * 1. Comment
 * 2. Table for details
 * 2a. Table Per team
 * 2b. Table for totals
 */

import tw, { css } from "twin.macro";

const CommentBox = () => {
  const [value, setValue] = useState("");
  return (
    <div css={tw`flex flex-col`}>
      <label css={tw`text-center bg-gray-200`}>Comments</label>
      <textarea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        css={tw`border`}
      />
    </div>
  );
};

const TasklistDetails = () => {
  const {
    currentDetailView,
    tasklistDetailsGroupedByTeamData,
    transposedTasklistDetailsData,
  } = useContext(PricingContext);

  return (
    <div css={tw`flex gap-8`}>
      <div css={tw``}>
        <CommentBox />
      </div>
      <div css={tw`flex flex-col gap-8`}>
        {currentDetailView === "tasklist" && (
          <div css={tw`flex gap-8`}>
            <TasklistDetailsTeamTable
              groupedData={tasklistDetailsGroupedByTeamData}
              rowData={transposedTasklistDetailsData}
            />
          </div>
        )}
        {currentDetailView === "cogs" && <COGSTable />}
      </div>
    </div>
  );
};

export default TasklistDetails;
