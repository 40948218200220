
import tw, { css } from "twin.macro";
import { useMemo, useState } from "react";
import { usePeopleTimeByMonthQuery } from "../../codegen/graphql-types";
import { FullSpinner } from "../../FullSpinner";
import { useAuthContext } from "../../useAuth";
import { Navigate } from "react-router-dom";
import { dashboardPage } from "../../routes";
import { DateTime } from "luxon";
import MonthYearPicker from "./MonthYearPicker";
import { 
  startOfDay,
  endOfDay
} from "date-fns";
import { nonBillableCompanyIds, peopleIdsToExclude } from "../../Time/constants";
import { sumEntryMinutes } from "../../Time/dates";
import { CUSTOM_TIME_TAGS_MAP } from "../../helpers/CUSTOM_TAG_MAP";
import { Button } from "@chakra-ui/react";

const minutesToHours = (minutes: number): number => Number((Math.round(minutes / 60)).toFixed(0));

export const TimeByMonth = function() {
  const { isAdmin } = useAuthContext();
  const [selectedDate, setSelectedDate] = useState(DateTime.now());

  // start and end dates for the query based on the selected month/year
  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: startOfDay(selectedDate.startOf("month").toJSDate()),
      endDate: endOfDay(selectedDate.endOf("month").toJSDate())
    };
  }, [selectedDate]);

  // Query for time entries for the selected month/year
  const { data, error, loading } = usePeopleTimeByMonthQuery({
    variables: {
      start_date: startDate,
      end_date: endDate
    },
    fetchPolicy: 'cache-and-network'
  });

  if (!isAdmin) {
    return <Navigate to={dashboardPage.getInstance()} />;
  }

  if (loading) {
    return <FullSpinner />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleDateChange = (newDate: DateTime) => {
    setSelectedDate(newDate);
    // You can perform any additional actions here, such as fetching data for the selected month/year
  };

  // Try to include only full-time employees
  const filteredPeople = data?.people
    .filter(person => {
      if (person.first_name === "Rabina" && person.id === "391887") {
        return false
      }
      return !peopleIdsToExclude.includes(person.id)
    })
    .filter(person => person.timeEntries.length > 0)
    .filter(person => person.company === "Maven Medical Marketing Inc.")
    .filter(person => !person.is_deleted_on_tw);

  const totalMinutes = filteredPeople?.reduce((acc, person) => {
    return acc + person.timeEntries.reduce(sumEntryMinutes, 0);
  }, 0) ?? 0;

  const totalBillableMinutes = filteredPeople?.reduce((acc, person) => {
    return acc + person.timeEntries.filter(entry => !nonBillableCompanyIds.includes(entry.company_id)).reduce(sumEntryMinutes, 0);
  }, 0) ?? 0;

  // Aggregate Time Entries
  const allNonBillableTimeEntries = filteredPeople?.map(person => person.timeEntries.filter(entry => nonBillableCompanyIds.includes(entry.company_id))).flat();
  const allSalesAndMarketingTimeEntries = allNonBillableTimeEntries?.filter(entry => Number(entry.project_tag_id) === CUSTOM_TIME_TAGS_MAP['Time: Sales and marketing']);
  const allInnovationAndDevelopmentTimeEntries = allNonBillableTimeEntries?.filter(entry => Number(entry.project_tag_id) === CUSTOM_TIME_TAGS_MAP['Time: Innovation and development']);

  // Aggregate Minutes
  const allNonBillableMinutesTotal = allNonBillableTimeEntries?.reduce(sumEntryMinutes, 0) ?? 0;
  const salesAndMarketingMinutesTotal = allSalesAndMarketingTimeEntries?.reduce(sumEntryMinutes, 0) ?? 0;
  const innovationAndDevelopmentMinutesTotal = allInnovationAndDevelopmentTimeEntries?.reduce(sumEntryMinutes, 0) ?? 0;
  // Lump 'Professional Development' and 'Operational' hours under Non-billable
  const nonBillableMinutesTotal = allNonBillableMinutesTotal - salesAndMarketingMinutesTotal - innovationAndDevelopmentMinutesTotal;

  
  return (
    <div css={[tw`p-4 w-full`, css`
      @media print {
        @page {
          size: landscape;
          margin: 1cm;
        }
      }
    `]}>
      <header css={tw`flex w-full justify-between items-center`}>
        <div>
          <h1 css={tw`text-2xl font-bold mb-4`}>Time By Month</h1>
          <MonthYearPicker onChange={handleDateChange} selectedDate={selectedDate} />
          <p css={tw`mt-4`}>
            Selected: {selectedDate.toFormat("MMMM yyyy")}
          </p>
        </div>
        <div>
          <Button onClick={() => window.print()} colorScheme="blue">Save as PDF</Button>
        </div>
      </header>
      <table css={[tw`w-full mt-4`, css`
        td, th {
          text-align: center; 
          padding: 0.5rem;
          border: 1px solid #e2e8f0;
        }
      `]}>
        <thead>
          <tr>
            <th rowSpan={2}>Employee</th>
            <th rowSpan={2}>Total Hours</th>
            <th colSpan={2}>Billable</th>
            <th colSpan={2}>Non-Billable</th>
            <th colSpan={2}>Sales & Marketing</th>
            <th colSpan={2}>Innovation & Development</th>
          </tr>
          <tr>
            <th>Hours</th>
            <th>%</th>
            <th>Hours</th>
            <th>%</th>
            <th>Hours</th>
            <th>%</th>
            <th>Hours</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {filteredPeople?.sort((a, b) => {
              const aTime = a.timeEntries.reduce((acc: any, entry: any) => acc + entry.minutes, 0);
              const bTime = b.timeEntries.reduce((acc: any, entry: any) => acc + entry.minutes, 0);
              return bTime - aTime;
            })
            .map(person => {
              // Time Entries
              const billableTimeEntries = person.timeEntries.filter(entry => !nonBillableCompanyIds.includes(entry.company_id))
              const nonBillableTimeEntries = person.timeEntries.filter(entry => nonBillableCompanyIds.includes(entry.company_id));
              const salesAndMarketingTimeEntries = nonBillableTimeEntries.filter(entry => Number(entry.project_tag_id) === CUSTOM_TIME_TAGS_MAP['Time: Sales and marketing']);
              const innovationAndDevelopmentTimeEntries = nonBillableTimeEntries.filter(entry => Number(entry.project_tag_id) === CUSTOM_TIME_TAGS_MAP['Time: Innovation and development']);
              // Minutes
              const totalSalesAndMarketingMinutes = salesAndMarketingTimeEntries.reduce(sumEntryMinutes, 0);
              const totalInnovationAndDevelopmentMinutes = innovationAndDevelopmentTimeEntries.reduce(sumEntryMinutes, 0);
              const totalMinutes = person.timeEntries.reduce(sumEntryMinutes, 0);
              const totalBillableMinutes = billableTimeEntries.reduce(sumEntryMinutes, 0);
              // For 'Professional Development' and 'Operational' hours we can just lump them under Non-billable minutes
              const nonBillableMinutes = nonBillableTimeEntries.reduce(sumEntryMinutes, 0) - totalSalesAndMarketingMinutes - totalInnovationAndDevelopmentMinutes;
              return (
                <tr key={person.id}>
                  {/* Employee */}
                  <td>{person.first_name} {person.last_name}</td>
                  {/* Total Hours */}
                  <td>
                    {minutesToHours(totalMinutes)}
                  </td>
                  {/* Billable */}
                  <td>
                    {minutesToHours(totalBillableMinutes)}
                  </td>
                  <td>
                    {((totalBillableMinutes / totalMinutes) * 100).toFixed(0)}%
                  </td>
                  {/* Non-Billable */}
                  <td>
                    {minutesToHours(nonBillableMinutes)}
                  </td>
                  <td>
                    {((nonBillableMinutes / totalMinutes) * 100).toFixed(0)}%
                  </td>
                  {/* Sales & Marketing */}
                  <td>
                    {minutesToHours(totalSalesAndMarketingMinutes)}
                  </td>
                  <td>
                    {((totalSalesAndMarketingMinutes / totalMinutes) * 100).toFixed(0)}%
                  </td>
                  {/* Innovation & Development */}
                  <td>
                    {minutesToHours(totalInnovationAndDevelopmentMinutes)}
                  </td>
                  <td>
                    {((totalInnovationAndDevelopmentMinutes / totalMinutes) * 100).toFixed(0)}%
                  </td>
                </tr>
              );
            }
          )}
          <tr>
            <th>&nbsp;</th>
            <th>Total Hours</th>
            <th>Billable</th>
            <th>%</th>
            <th>Non-Billable</th>
            <th>%</th>
            <th>Sales & Marketing</th>
            <th>%</th>
            <th>Innovation & Development</th>
            <th>%</th>
          </tr>
          <tr>
            <th>&nbsp;</th> 
            <th>{minutesToHours(totalMinutes)}</th>
            <th>{minutesToHours(totalBillableMinutes)}</th>
            <th>{((totalBillableMinutes / totalMinutes) * 100).toFixed(0)}%</th>
            <th>{minutesToHours(nonBillableMinutesTotal)}</th>
            <th>{((nonBillableMinutesTotal / totalMinutes) * 100).toFixed(0)}%</th>
            <th>{minutesToHours(salesAndMarketingMinutesTotal)}</th>
            <th>{((salesAndMarketingMinutesTotal / totalMinutes) * 100).toFixed(0)}%</th>
            <th>{minutesToHours(innovationAndDevelopmentMinutesTotal)}</th>
            <th>{((innovationAndDevelopmentMinutesTotal / totalMinutes) * 100).toFixed(0)}%</th>
          </tr>
        </tbody>
        
        
      </table>
    </div>
  );
}