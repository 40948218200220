/** @jsxImportSource @emotion/react */

import { useEffect, useState, useMemo, useContext } from "react";
import tw, { css } from "twin.macro";
import { PricingContext } from "../../helpers/usePricing";

/**
 * Team component for the tasklist pricing details
 *
 * Possible layout
 * 1. Team name
 * 2. Columns of seniority with total and cost
 * 3. Total
 */

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

// const columnHelper = createColumnHelper<ProjectedTimeType>();
const TableCell = ({ getValue, row, column, table }: any) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value);
  };
  return (
    <input
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={onBlur}
      css={tw`bg-white text-center`}
      style={{ width: `${value.toString().length + 2}ch` }}
      type="number"
    />
  );
};

const TasklistDetailsTeamTable = ({
  groupedData,
  rowData,
}: {
  groupedData: any;
  rowData: any;
}) => {
  const { updateTeamRoleHour, formatNumber } = useContext(PricingContext);
  const columns = useMemo(
    () => [
      {
        header: "",
        id: "column-header",
        accessorFn: (_: any, index: number) => {
          if (index === 0) {
            return "Projected Budget";
          } else if (index === 1) {
            return "Actuals";
          } else {
            return "";
          }
        },
      },
      ...Object.entries(groupedData).map(([teamName, teamData]: any) => ({
        header: teamName,
        accessorFn: `${teamName}-team`,
        columns: [
          // Columns for each role
          ...teamData.map((td: any) => ({
            header: td.role,
            accessorFn: `${teamName}-${td.role}-name`,
            id: `${teamName}-${td.role}-cost`,
            columns: [
              {
                header: "Hrs",
                id: `${teamName}-${td.role}-time`,
                accessorFn: (row: any) => row[`${teamName}-${td.role}-time`],
                cell: (props: any) => {
                  return props.row.index === 0 ? (
                    <TableCell {...props} />
                  ) : (
                    <div>
                      {props.row.original[`${teamName}-${td.role}-time`]}
                    </div>
                  );
                },
              },
              {
                header: "Cost",
                id: `${teamName}-${td.role}-cost`,
                accessorFn: (row: any) => {
                  if (row[`${teamName}-${td.role}-cost`]) {
                    return `$${formatNumber(
                      row[`${teamName}-${td.role}-cost`]
                    )}`;
                  } else {
                    return "";
                  }
                },
              },
            ],
          })),
          // Column for the total (Team total)
          {
            header: "Total",
            accessorFn: `${teamName}-total-name`,
            id: `${teamName}-total-name`,
            columns: [
              {
                header: "Hrs",
                id: `${teamName}-total-time`,
                accessorFn: (row: any) => row[`${teamName}-total-time`],
              },
              {
                header: "Cost",
                id: `${teamName}-total-cost`,
                accessorFn: (row: any) =>
                  `$${formatNumber(row[`${teamName}-total-cost`])}`,
              },
            ],
          },
        ],
      })),
      {
        header: "Total",
        id: "total",
        accessorFn: (row: any) => row[`total-cost`],
        columns: [
          {
            header: "Hrs",
            id: "total-time",
            accessorFn: (row: any) => row[`total-time`],
          },
          {
            header: "Cost",
            id: "total-cost",
            accessorFn: (row: any) => `$${formatNumber(row[`total-cost`])}`,
          },
        ],
      },
    ],
    [groupedData]
  );

  const table = useReactTable({
    data: rowData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: string) => {
        updateTeamRoleHour(rowIndex, columnId, value);
      },
    },
    debugTable: true,
  });
  return (
    <div>
      <table css={tw`border-collapse border border-gray-200`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    css={tw`border border-gray-200 px-2 py-1`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} css={tw`border border-gray-200 text-center`}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TasklistDetailsTeamTable;
