/** @jsxImportSource @emotion/react */

import { useContext } from "react";
import { PricingContext } from "../helpers/usePricing";
import tw, { css } from "twin.macro";
import ProjectTasklistPricingTable from "./ProjectTasklistPricingTable";
import ProjectSelector from "./ProjectSelector";
import ProjectStatus from "./ProjectStatus";
import TasklistDetails from "./tasklist/TasklistDetails";
// import ComparableSelector from "./ComparableSelector";

export const ProjectPricingPage = () => {
  const {
    selectedProjectId,
    selectedProjectTasklists,
    projectedTimes,
  } = useContext(PricingContext);

  return (
    <div>
      <div css={tw`px-8 py-8`}>
        <h1>Project Pricing</h1>
      </div>

      {/* Project Selector */}
      <div css={tw`grid grid-cols-2 px-8`}>
        <ProjectSelector />
        {/* <ProjectStatus /> */}
      </div>

      <hr css={tw`my-4`} />

      {/* Project Pricing Table */}

      {/* PHASE 2: Comparables */}
      {/* <div css={tw`px-8`}>
        <ComparableSelector />
      </div> */}

      {/* Project Pricing Table */}
      <div css={tw`px-8`}>
        {selectedProjectTasklists && selectedProjectTasklists.length > 0 && (
          <div key={selectedProjectId}>
            <ProjectTasklistPricingTable />
          </div>
        )}
      </div>

      {/* Tasklist Details Table */}
      <hr css={tw`my-4`} />

      <div css={tw`px-8`}>
        {projectedTimes && projectedTimes.length > 0 && <TasklistDetails />}
      </div>
    </div>
  );
};
