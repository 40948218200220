/** @jsxImportSource @emotion/react */

import { useContext } from "react";
import { PricingContext } from "../helpers/usePricing";
import tw, { css } from "twin.macro";
import { gql, useLazyQuery } from "@apollo/client";
import AsyncSelect from "react-select/async";

//  https://mavenmm.teamwork.com/projects/api/v3/projects/496857/tasklists
const ProjectSelector = () => {
  const { handleSelectProject } = useContext(PricingContext);

  // Fetch Projects from DB
  const [searchProjects] = useLazyQuery<any>(
    gql`
      query SearchProjectsForEstimator($keyword: String) {
        searchProjects: projects(
          where: {
            AND: [
              {
                OR: [
                  { name: { contains: $keyword } }
                  { description: { contains: $keyword } }
                  { component_code: { contains: $keyword } }
                ]
              }
              { is_archived: { equals: false } }
            ]
          }
          first: 10
        ) {
          id
          name
        }
      }
    `
  );

  // Fetch options for dropdown select
  const promiseOptions = async (inputValue: string) => {
    try {
      return new Promise<any[]>(async (resolve) => {
        // If keyword is empty, don't search
        if (inputValue === "" || inputValue === undefined) {
          resolve([]);
        }

        const res = await searchProjects({
          variables: { keyword: inputValue },
        });

        if (res.data && res.data.searchProjects) {
          const formattedOptions = res?.data?.searchProjects.map(
            (project: any) => ({
              label: project.name, // Assuming you want to show the project name
              value: project.id, // Assuming the value should be the project id
            })
          );

          resolve(formattedOptions);
        }

        resolve([]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div css={tw`max-w-sm`}>
      <AsyncSelect
        cacheOptions
        loadOptions={promiseOptions}
        defaultOptions
        onChange={handleSelectProject}
      />
    </div>
  );
};

export default ProjectSelector;
