import React from "react";
import { Navigate, Route, Routes } from "react-router-dom"; // Use Navigate instead of Redirect
import { Nav } from "./Nav/Nav";
import "./App.css";

// Financials pages
import { ClientInvoicesPage } from "./Financials/Invoices/ClientInvoicesPage";
import { Estimates } from "./Financials/Estimates/Estimates";
import { GrossProfitSummary } from "./Financials/Performance/GrossProfitSummary";
import { QuickbooksInvoice } from "./Financials/Invoices/QuickbooksInvoice";

// Projects pages
import { ProjectsPage } from "./Projects/Dashboard/ProjectsPage";
import { CreateExpensePage } from "./Projects/Expenses/CreateExpensePage";

// Time pages
import { TimeForUser } from "./Time/Employee/TimeForUser";
import { TimeForPod } from "./Time/Pod/TimeForPod";

// Admin pages
import { AdminTimePage } from "./Admin/Time/AdminTime";
import { AdminPage } from "./Admin/Forecasting/Admin";
import { TimeByMonth } from "./Admin/TimeByMonth/TimeByMonth";

import * as routes from "./routes";

// helpers & hooks & providers
import { EstimatesProvider } from "./helpers/useEstimates";
import { PeopleProvider } from "./helpers/usePeopleContext";
import { RecentTimeProvider } from "./helpers/useRecentTimeContext";
import { PodTimeProvider } from "./helpers/usePodTimeContext";
import { QuickbooksResourceProvider } from "./helpers/useQuickbooksContext";
import { QuickbooksAuthContextProvider } from "./useQuickbooksAuth";

// Pricing
import { ProjectPricingPage } from "./Pricing/PricingPage";
import { PricingProvider } from "./helpers/usePricing";

const LoggedInProviders = ({ children }: { children: React.ReactNode }) => (
  <EstimatesProvider>
    <PeopleProvider>{children}</PeopleProvider>
  </EstimatesProvider>
);

export const AppLoggedIn = () => {
  return (
    <LoggedInProviders>
      <Nav />
      <Routes>
        {/* Redirect root to dashboard */}
        <Route
          path="/"
          element={<Navigate to={routes.dashboardPage.getInstance()} />}
        />

        {/* Financials */}
        <Route
          path={routes.grossProfitReport.definition}
          element={<GrossProfitSummary />}
        />
        <Route path={routes.estimatesPage.definition} element={<Estimates />} />

        {/* Ensure createInvoice route "/financials/invoices/[path]" is defined before the route clientInvoicesPage "/financials/invoices" */}
        <Route path={routes.createInvoice.definition} element={
          <QuickbooksAuthContextProvider>
            <QuickbooksResourceProvider>
              <QuickbooksInvoice />
            </QuickbooksResourceProvider>
          </QuickbooksAuthContextProvider>
        }/>
        <Route path={routes.viewInvoice.definition} element={
          <QuickbooksAuthContextProvider>
            <QuickbooksResourceProvider>
              <QuickbooksInvoice />
            </QuickbooksResourceProvider>
          </QuickbooksAuthContextProvider>
        }/>
        <Route path={routes.clientInvoicesPage.definition} element={
          <QuickbooksAuthContextProvider>
            <ClientInvoicesPage />
          </QuickbooksAuthContextProvider>
        }/>

        {/* Projects */}
        <Route
          path={routes.dashboardPage.definition}
          element={<ProjectsPage />}
        />
        <Route
          path={routes.newExpense.definition}
          element={<CreateExpensePage />}
        />

        {/* Time */}
        <Route
          path={routes.timeEmployee.definition}
          element={
            <RecentTimeProvider>
              <TimeForUser />
            </RecentTimeProvider>
          }
        />
        <Route
          path={routes.timePod.definition}
          element={
            <PodTimeProvider>
              <TimeForPod />
            </PodTimeProvider>
          }
        />

        {/* Pricing */}
        <Route
          path={routes.pricingPage.definition}
          element={
            <PricingProvider>
              <ProjectPricingPage />
            </PricingProvider>
          }
        />

        {/* Admin */}
        <Route path={routes.adminTime.definition} element={<AdminTimePage />} />
        <Route
          path={routes.adminForecasting.definition}
          element={<AdminPage />}
        />
        <Route
          path={routes.adminTimeByMonth.definition}
          element={<TimeByMonth />}
        />
      </Routes>
    </LoggedInProviders>
  );
};
